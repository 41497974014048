var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ValidationObserver', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-modal', {
          attrs: {
            "id": "modal-send-queues",
            "title": _vm.$t('reservation.sendQueues.title'),
            "title-class": "text-airline font-medium-4 font-weight-bolder",
            "centered": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "size": "lg"
          },
          on: {
            "show": _vm.openModalHandle
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn(_ref2) {
              var close = _ref2.close;
              return [_c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(186, 191, 199, 0.15)',
                  expression: "'rgba(186, 191, 199, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "center rounded-pill width-100",
                attrs: {
                  "size": "md",
                  "variant": "outline-secondary"
                },
                on: {
                  "click": function click($event) {
                    return close();
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "btn-gradient mt-lg-25  border-0",
                attrs: {
                  "disabled": !_vm.queueCode || invalid,
                  "pill": ""
                },
                on: {
                  "click": _vm.sendQueuesHandle
                }
              }, [_c('span', {
                staticClass: "align-middle"
              }, [_vm._v(_vm._s(_vm.$t('reservation.sendQueues.title')))])])];
            }
          }], null, true)
        }, [_c('div', {
          staticClass: "font-medium-4 fw-700"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bookingCode')) + ": "), _c('span', {
          staticClass: "text-info text-uppercase"
        }, [_vm._v(_vm._s(_vm.bookingData.bookingCode))])]), _c('div', {
          staticClass: "text-danger font-italic"
        }, [_vm._v(" (***) Lưu ý: bạn sẽ chịu mọi trách nhiệm cho thao tác gửi Q này, luôn theo dõi phản hồi của hãng sau khi gửi Q. ")]), _c('b-form-group', {
          attrs: {
            "label-for": "queueCode"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('reservation.sendQueues.chooseQueue')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          attrs: {
            "id": "queueCode",
            "taggable": "",
            "options": _vm.queueCodeOptions,
            "reduce": function reduce(val) {
              return val.code;
            },
            "label": "code",
            "filterable": "",
            "input-id": "queueCode",
            "placeholder": _vm.$t('reservation.sendQueues.placeholderQueueCode')
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(item) {
              return [_c('b-row', [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('code', {
                staticClass: "font-weight-bolder mb-0 bg-transparent text-nowrap"
              }, [_vm._v(" " + _vm._s(item.code) + _vm._s("".concat(item.title ? " - ".concat(item.title) : '')) + " ")])]), _c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('small', {
                staticClass: "text-wrap"
              }, [_vm._v(" " + _vm._s(item.description) + " ")])])], 1)];
            }
          }, {
            key: "selected-option",
            fn: function fn(item) {
              return [_c('code', {
                staticClass: "font-weight-bolder mb-0 bg-transparent"
              }, [_vm._v(" " + _vm._s(item.code) + _vm._s("".concat(item.title ? " - ".concat(item.title) : '')) + " ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.queueCode,
            callback: function callback($$v) {
              _vm.queueCode = $$v;
            },
            expression: "queueCode"
          }
        })], 1), _vm.queueCode && ['CN'].includes(_vm.queueCode) ? _c('div', [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('reservation.sendQueues.oldPassenger'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "newPassenger"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('reservation.sendQueues.oldPassenger')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "id": "oldPassenger",
                  "options": _vm.passengerOptions,
                  "reduce": function reduce(val) {
                    return val;
                  },
                  "label": "firstName",
                  "filterable": "",
                  "input-id": "queueCode",
                  "placeholder": _vm.$t('reservation.sendQueues.oldPassenger')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(item) {
                    return [_vm._v(" " + _vm._s("".concat(item.title, " ").concat(item.lastName, " ").concat(item.firstName)) + " ")];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(item) {
                    return [_vm._v(" " + _vm._s("".concat(item.title, " ").concat(item.lastName, " ").concat(item.firstName)) + " ")];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.oldPassenger,
                  callback: function callback($$v) {
                    _vm.oldPassenger = $$v;
                  },
                  expression: "oldPassenger"
                }
              }), _c('BFormInvalidFeedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        }), _c('b-form-group', {
          attrs: {
            "label-for": "newPassenger"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('reservation.sendQueues.changeName')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "4"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('reservation.Title'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('v-select', {
                attrs: {
                  "id": "title",
                  "options": _vm.titleOptions,
                  "reduce": function reduce(val) {
                    return val;
                  },
                  "label": "label",
                  "filterable": "",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "input-id": "queueCode",
                  "placeholder": _vm.$t('reservation.Title')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(item) {
                    return [_vm._v(" " + _vm._s(item.label) + " ")];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(item) {
                    return [_vm._v(" " + _vm._s(item.label) + " ")];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.newPassenger.title,
                  callback: function callback($$v) {
                    _vm.$set(_vm.newPassenger, "title", $$v);
                  },
                  expression: "newPassenger.title"
                }
              })];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "4"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('reservation.Lastname'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-input', {
                attrs: {
                  "id": "lastName",
                  "lazy-formatter": "",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimUpperCaseInput,
                  "placeholder": _vm.$t('reservation.Lastname')
                },
                model: {
                  value: _vm.newPassenger.lastName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.newPassenger, "lastName", $$v);
                  },
                  expression: "newPassenger.lastName"
                }
              })];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "4"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('reservation.Lastname'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-input', {
                attrs: {
                  "id": "firstName",
                  "lazy-formatter": "",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimUpperCaseInput,
                  "placeholder": _vm.$t('reservation.Firstname')
                },
                model: {
                  value: _vm.newPassenger.firstName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.newPassenger, "firstName", $$v);
                  },
                  expression: "newPassenger.firstName"
                }
              })];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1) : _vm.queueCode && ['SC', 'SCD'].includes(_vm.queueCode) ? _c('div', [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('reservation.sendQueues.chooseSegmentToSC'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('reservation.sendQueues.chooseSegmentToSC')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_vm.segmentTK.length ? _c('div', [_c('b-form-radio-group', {
                staticClass: "custom-checkbox",
                attrs: {
                  "name": _vm.$t('reservation.sendQueues.chooseSegmentToSC'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "plain": "",
                  "stacked": ""
                },
                model: {
                  value: _vm.segmentToSC,
                  callback: function callback($$v) {
                    _vm.segmentToSC = $$v;
                  },
                  expression: "segmentToSC"
                }
              }, _vm._l(_vm.segmentTK, function (seg) {
                return _c('b-form-radio', {
                  key: seg.segmentId,
                  attrs: {
                    "value": seg.segmentId
                  }
                }, [_c('span', {
                  staticClass: "fw-600 font-medium-2"
                }, [_vm._v(_vm._s(seg.trip))])]);
              }), 1)], 1) : _c('div', {
                staticClass: "text-danger fw-600"
              }, [_vm._v(" " + _vm._s(_vm.$t('reservation.sendQueues.noSegmentTK')) + " ")])])];
            }
          }], null, true)
        })], 1) : _vm._e(), _c('b-alert', {
          staticClass: "p-1",
          attrs: {
            "show": _vm.queueCode && _vm.queueCode.description ? true : false,
            "variant": "warning"
          }
        }, [_c('div', [_c('small', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.queueCode && _vm.queueCode.description) + " ")])])]), _vm.queueCode && ['SC', 'SCD'].includes(_vm.queueCode) ? _c('b-alert', {
          staticClass: "p-1",
          attrs: {
            "show": "",
            "variant": "danger"
          }
        }, [_c('div', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" Lưu ý các bước bắt buộc để xác nhận chỗ SC : ")]), _c('div', {
          staticClass: "ml-50"
        }, [_c('div', [_vm._v("1. Đặt chổ hạng B với tình trạng HK cho chuyến cần xác nhận.")]), _c('div', [_vm._v("2. Đặt sổ chờ (GL) hạng cũ cho chuyến cần xác nhận.")]), _vm.queueCode === 'SC' ? _c('div', [_vm._v(" 3. Thực hiện gỡ checkin trước khi gởi Queue nếu có. ")]) : _vm._e()])]) : _vm._e()], 1), _c('ModalShowText', {
          attrs: {
            "text": _vm.responseCommand,
            "title-modal": _vm.$t('reservation.sendQueues.title'),
            "id-modal": _vm.idModal
          }
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }